import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CameraOutdoorIcon from '@mui/icons-material/CameraOutdoor';
import CodeIcon from '@mui/icons-material/Code';
import BusinessIcon from '@mui/icons-material/Business';
import CommonTab from './commontab';
import development from './images/development.jpg';
import office from './images/office.jpg';
import atm1 from './images/atm1.jpg';
import surveillance from './images/surveillance.jpg';



export default function WorkingTabs(props) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div id='workingtab'>
        <div className='container'>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                  <Tab icon={<CodeIcon fontSize="large" />} iconPosition="start" label="DEVELOPMENT " value="1" />
                  <Tab icon={<BusinessIcon fontSize="large" />} iconPosition="start" label="OFFICE " value="2" />
                  <Tab icon={<AccountBalanceIcon fontSize="large" />} iconPosition="start" label="ATM " value="3" />
                  <Tab icon={<CameraOutdoorIcon fontSize="large" />} iconPosition="start" label="E-SURVEILLANCE " value="4" />
                </TabList>
              </Box>


              <TabPanel value="1">
                <CommonTab
                  header="DEVELOPMENT"
                  subheader="Our software development highlights"
                  ul1="End-to-end software development – we envision, plan, design, develop, integrate, deploy, and support software solutions."
                  ul2="In-house project management office (PMO) – our PMs are experienced in large complex projects with distributed teams and multiple vendors involved."
                  ul3="Services built around strict agreed KPIs – business outcomes, quality of delivered software, code quality, development process efficiency, project time and costs (actual costs vs budget, budget to completion vs budget remaining, etc.), team’s productivity, and customer/user satisfaction."
                  imgsrc={development}
                />
                {/*<Button className='btn btn-custom btn-lg page-scroll' href='' >Read More</Button> */}

              </TabPanel>


              <TabPanel value="2">
                <CommonTab
                  header="OFFICE"
                  subheader="We have workspace and  team of talented employee."
                  ul1="Your business data and other important documents that carry crucial information could be the target of competitors. With VProtect, you can have complete peace of mind as your office stays under constant vigilance. We also provide you with smart security solutions to physically safeguard your computers, data and employees."
                  imgsrc={office}
                />
              </TabPanel>


              <TabPanel value="3">
                <div className="row">

                  <div className="col-xs-12 col-md-6">
                    <div className="workingtab-text">
                      <h2>Atm</h2>
                      <h3>Our software development highlights</h3>
                      <p>
                        <ul>End-to-end software development – we envision, plan, design, develop, integrate, deploy, and support software solutions.</ul>
                        <ul>In-house project management office (PMO) – our PMs are experienced in large complex projects with distributed teams and multiple vendors involved.</ul>
                        <ul>Services built around strict agreed KPIs – business outcomes, quality of delivered software, code quality, development process efficiency, project time and costs (actual costs vs budget, budget to completion vs budget remaining, etc.), team’s productivity, and customer/user satisfaction.</ul>
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    {" "}
                    <img src="img/work/atm1.jpg" className="img-responsive" alt="" />{" "}
                  </div>
                </div>
              </TabPanel>


              <TabPanel value="4">
                <div className="row">

                  <div className="col-xs-12 col-md-6">
                    <div className="workingtab-text">
                      <h2>Surveillance</h2>
                      <h3>Our software development highlights</h3>
                      <p>
                        <ul>End-to-end software development – we envision, plan, design, develop, integrate, deploy, and support software solutions.</ul>
                        <ul>In-house project management office (PMO) – our PMs are experienced in large complex projects with distributed teams and multiple vendors involved.</ul>
                        <ul>Services built around strict agreed KPIs – business outcomes, quality of delivered software, code quality, development process efficiency, project time and costs (actual costs vs budget, budget to completion vs budget remaining, etc.), team’s productivity, and customer/user satisfaction.</ul>
                      </p>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    {" "}
                    <img src="img/work/surveillance.jpg" className="img-responsive" alt="" />{" "}
                  </div>
                </div>
              </TabPanel>
            </TabContext>
          </Box>

        </div>
      </div>
    </>
  );
}
